<template>
  <div class="row">
    <div class="flex xs12">
      <va-card
        class="no-h-padding"
        :title="$t('teams.new')"
      >
        <actions
          slot="actions"
          crud-links="teams"
          :actions="actions"
        />
        <form-wizard
          title=""
          subtitle=""
          :color="$themes.primary"
          :next-button-text="$t('layout.wizard.next')"
          :back-button-text="$t('layout.wizard.back')"
          :finish-button-text="$t('layout.wizard.finish')"
          @on-complete="submit"
        >
          <tab-content
            :title="$t('teams.wizard.data')"
            :before-change="isTeamValid"
          >
            <div class="text-center">
              <p><strong>{{ $t('teams.wizard.form.data') }}</strong></p>
            </div>
            <team-form
              ref="teamForm"
              :team="team"
              :loading="loading"
              @submit="adjustTeam"
            />
          </tab-content>
          <tab-content
            :title="$t('teams.wizard.members')"
            :before-change="isMembersValid"
          >
            <div class="text-center mb-4">
              <p><strong>{{ $t('teams.wizard.form.members') }}</strong></p>
            </div>
            <members-table
              ref="membersTable"
              :edit="true"
              :team="teamMembers"
              :loading="loading"
              @submit="adjustMembers"
            />
          </tab-content>
          <tab-content
            :title="$t('teams.wizard.planning')"
            :before-change="isPlanningValid"
          >
            <div class="text-center">
              <p><strong>{{ $t('teams.wizard.form.planning') }}</strong></p>
            </div>
            <planification-form
              ref="planificationForm"
              class="mb-4"
              :loading="loading"
              :leaders-list="members"
              @submit="adjustTeamPlan"
            />
          </tab-content>
          <tab-content
            :title="$t('teams.wizard.annual_questions')"
            :before-change="isPreachingValid"
          >
            <div class="text-center mb-4">
              <p><strong>{{ $t('teams.wizard.form.preaching') }}</strong></p>
            </div>
            <preaching-form
              ref="preachingForm"
              class="mb-4"
              :loading="loading"
              @submit="adjustTeamPreaching"
            />
          </tab-content>
          <tab-content :title="$t('teams.wizard.confirm')">
            <transition-group
              name="fade"
              mode="out-in"
            >
              <div
                key="review"
                v-show="!loading && !submitError"
              >
                <div class="text-center">
                  <h4>{{$t('teams.wizard.form.confirm')}}</h4>
                  <p>{{$t('teams.wizard.form.verify')}}</p>
                </div>
                <team-summary
                  :loading="loading"
                  :show-links="false"
                  :team="usedTeam"
                  :teamMembers="teamMembers"
                  :plan="usedPlan"
                  :questions="usedPreaching"
                />
              </div>
              <div
                key="completed"
                v-show="loading"
              >
                <div class="text-center">
                  <h4>{{$t('teams.wizard.completed')}}</h4>
                  <p>{{$t('teams.wizard.form.completed')}}</p>
                </div>
              </div>
              <div
                key="error"
                v-show="submitError"
              >
                <div class="text-center">
                  <h4>{{$t('teams.wizard.error')}}</h4>
                  <p>{{$t('teams.wizard.form.submit_error')}}</p>
                  <va-button
                    color="primary"
                    @click.prevent="submit"
                  >
                    {{$t('teams.wizard.retry')}}
                  </va-button>
                </div>
              </div>
            </transition-group>
          </tab-content>
        </form-wizard>
      </va-card>
    </div>
  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

const TeamForm = () => import(/* webpackPrefetch: true */ './Form')
const TeamSummary = () => import(/* webpackPrefetch: true */ './Summary')
const PreachingForm = () => import(/* webpackPrefetch: true */ './Preaching')
const PlanificationForm = () => import(/* webpackPrefetch: true */ './Planification')
const MembersTable = () => import(/* webpackPrefetch: true */ '../Members')
const Actions = () => import(/* webpackPrefetch: true */ '@/components/extras/Actions')

export default {
  name: 'teams-new',
  components: {
    Actions,
    TeamForm,
    TeamSummary,
    PreachingForm,
    PlanificationForm,
    MembersTable,
    FormWizard,
    TabContent,
  },
  data () {
    return {
      actions: ['index'],
      loading: false,
      submitError: false,
      team: {},
      members: [],
      teamMembers: {
        users: [],
      },
      usedTeam: null,
      usedPlan: null,
      usedPreaching: null,
    }
  },
  methods: {
    teamChange () {
      if (this.$refs.teamForm.hasSelectedTeam && this.$refs.teamForm.selectedTeam.users) {
        this.teamMembers = {
          id: this.$refs.teamForm.selectedTeam.id,
          leader_id: this.$refs.teamForm.selectedTeam.leader_id,
          users: this.$refs.teamForm.selectedTeam.users.slice(0),
        }
      } else if (this.$refs.teamForm.selectedLeader !== null) {
        this.teamMembers = {
          id: 0,
          leader_id: this.$refs.teamForm.selectedLeader.id,
          users: [this.$refs.teamForm.selectedLeader],
        }
      }
      this.$refs.teamForm.submit()
    },
    isTeamValid () {
      const valid = this.$refs.teamForm && !this.$refs.teamForm.notValid
      if (!valid) return false

      this.teamChange()
      return true
    },
    membersChange () {
      if (this.$refs.membersTable.membersList) {
        this.members = this.$refs.membersTable.membersList.slice(0)
      }
      this.$refs.membersTable.submit()
    },
    isMembersValid () {
      const valid = this.$refs.membersTable && !this.$refs.membersTable.haveErrors
      if (!valid) return false

      this.membersChange()
      return true
    },
    planningChange () {
      this.$refs.planificationForm.submit()
    },
    isPlanningValid () {
      const valid = this.$refs.planificationForm && !this.$refs.planificationForm.notValid
      if (!valid) return false

      this.planningChange()
      return true
    },
    preachingChange () {
      this.$refs.preachingForm.submit()
    },
    isPreachingValid () {
      this.preachingChange()
      return true
    },
    submitChange () {
      this.submit()
      return true
    },
    adjustTeam (team) {
      this.usedTeam = team
    },
    adjustMembers (members) {
      this.usedTeam.users = members.users
    },
    adjustTeamPlan (plan) {
      this.usedPlan = plan
    },
    adjustTeamPreaching (preaching) {
      this.usedPreaching = preaching
    },
    async submit () {
      if (this.loading) return

      this.loading = true
      const teamPlan = {
        team: Object.assign({}, this.usedTeam),
        plan: Object.assign({}, this.usedPlan),
        annual_answers: JSON.stringify(this.usedPreaching),
      }

      const fecha = new Date()
      const mesActual = fecha.getMonth() + 1

      if (mesActual >= 5) { teamPlan.team.year = fecha.getFullYear() + 1 } else { teamPlan.team.year = fecha.getFullYear() }

      // teamPlan.team.year = '2024'

      let response = null
      try {
        response = await this.$http.post('teams/inscription', teamPlan)
      } catch (e) {
        // console.log('Error', e)
        this.loading = false
        return
      }

      if (!this.usedTeam.id) {
        this.usedTeam.id = response.data.data.team_id
      }

      try {
        await this.teamHistory()
      } catch (e) {
        console.log('Error generating history', e)
      }

      this.$router.push({ name: 'teamsIndex' })
      this.loading = false
    },
    async teamHistory () {
      const data = {
        originals: [],
        currents: this.usedTeam.users._ids,
      }

      try {
        await this.$http.post('teams/history/' + this.usedTeam.id, data)
      } catch (e) {
        // console.log('History error', e)
      }
    },
  },
}
</script>
